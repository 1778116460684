import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaGithub, FaTwitter, FaLinkedin } from 'react-icons/fa';

const About = () => {

    return (
        <div className="about-content">
            <div className="about-profileImg">
                <div className="about-profileImg-content">
                    <AnimatePresence>
                        <motion.h1
                            initial={{ opacity: 0, x: -20, y: 60 }}
                            animate={{ opacity: 1, x: 0, y: 0 }}
                            // transition={{ type: 'spring', stiffness: 200 }}
                            transition={{ type: 'spring', stiffness: 200, delay: 1 }}
                            exit={{ opacity: 0, x: -100, y: 50 }}
                        // exit={{ opacity: 0, y: 20, clipPath: 'polygon(0 100%, 100% 100%, 100% 0, 0 0)' }}
                        >
                            About Me
                        </motion.h1>
                    </AnimatePresence>
                    <AnimatePresence>
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            // transition={{ duration: 0.05, delay: 0.2 }}
                            // transition={{ type: 'spring', stiffness: 200, delay: 0.2 }}
                            transition={{ type: 'spring', stiffness: 200, delay: 1.2 }}
                            exit={{ opacity: 0, y: 20 }}
                            className="about-profileImg-content-details"
                        >
                            <motion.p
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                // transition={{ duration: 0.2, delay: 0.4 }}
                                // transition={{ type: 'spring', stiffness: 200, delay: 0.4 }}
                                transition={{ type: 'spring', stiffness: 200, delay: 1.4 }}
                                exit={{ opacity: 0, y: 20 }}
                            >
                                &gt; Hong Konger
                            </motion.p>
                            <motion.p
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                // transition={{ duration: 0.2, delay: 0.7 }}
                                // transition={{ type: 'spring', stiffness: 200, delay: 0.7 }}
                                transition={{ type: 'spring', stiffness: 200, delay: 1.7 }}
                                exit={{ opacity: 0, y: 20 }}
                            >
                                &gt; HKPolyU Computer Science UG student.
                            </motion.p>
                            <motion.p
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                // transition={{ duration: 0.2, ease: 'easeInOut', delay: 0.8 }}
                                // transition={{ type: 'spring', stiffness: 200, delay: 0.8 }}
                                transition={{ type: 'spring', stiffness: 200, delay: 1.8 }}
                                exit={{ opacity: 0, y: 20 }}
                            >
                                &gt; Learner of Web Development and React.
                            </motion.p>
                        </motion.div>
                    </AnimatePresence>
                    <AnimatePresence>
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            // transition={{ duration: 0.5, ease: 'easeInOut', delay: 1 }}
                            transition={{ duration: 0.5, ease: 'easeInOut', delay: 2 }}
                            exit={{ opacity: 0 }}
                            className="about-profileImg-btn"
                        >
                            <a className="btn" href='/resume_taileena.pdf' >View My Resume</a>
                        </motion.div>
                    </AnimatePresence>
                </div>
            </div>
            <div className="about-bio">
                <div className="about-text">
                    <AnimatePresence>
                        <motion.div
                            initial={{ y: 110 }}
                            animate={{ y: 0 }}
                            transition={{ type: 'spring', stiffness: 100, delay: 0.7 }}
                        >
                            <h1>Hello,</h1>
                            <h1>I am Lena Tai</h1>
                        </motion.div>
                    </AnimatePresence>
                </div>
                <AnimatePresence>
                    <motion.p
                        initial={{ opacity: 0, x: '100vh' }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ type: 'spring', stiffness: 50, delay: 0.4 }}
                        className='about-description'
                    >
                        As a 3rd year Computer Science student, technology has been a lifelong passion of mine. I have a strong foundation in HTML, CSS, JavaScript (ES6+), as well as knowledge of programming languages such as Python, Java and C/C++.
                        I'm proficient in using frameworks and tools such as React, Express, Node.js, and Git for web development. Continuously expanding my skills in these areas brings me great fulfillment. My aim is to become a well-rounded software engineer, ready to apply my capabilities to innovative technology projects.
                    </motion.p>
                </AnimatePresence>
                <div className="about-email">
                    <h1>Personal Email</h1>
                    <a href="mailto:taileeena@gmail.com">
                        <p>taileeena@gmail.com</p>
                    </a>
                </div>
            </div>
            <motion.div
                initial={{ opacity: 0, x: '100vh' }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ type: 'spring', stiffness: 60, delay: 2.1 }}
                className="about-social-links"
            >
                <a href="https://github.com/lenatai728"><span><FaGithub className='icon'/></span></a>
                <a href="https://x.com/LenaTai0728"><span><FaTwitter className='icon'/></span></a>
                <a href="https://www.linkedin.com/in/leena-tai-00361b323"><span><FaLinkedin className='icon'/></span></a>
            </motion.div>
        </div>
    );
};

export default About;


