import React from 'react'
import { Link as ScrollLink } from 'react-scroll';
import { FaGithub, FaTwitter, FaLinkedin } from 'react-icons/fa';
import profilePic from '../images/profile_pic.jpeg';
import './footer.styled.css';

const Footer = () => {
  return (
    <footer>
      <div className="footer-container">
        {/* PHOTO */}
        <div className="footer-grid-photo-container">
          <img src={profilePic} alt="footer logo" />
        </div>
        {/* NAV */}
        <ul className="footer-grid-nav-container">
          <p className='footer-nav-title'>LeeNa TAI</p>
          {/* LINKS */}
          <li>
            <ScrollLink to='about' smooth duration={450} className='nav-links'>
              Home
            </ScrollLink>
          </li>
          <li>
            <ScrollLink to='skills' smooth duration={450} className='nav-links'>
              Skills
            </ScrollLink>
          </li>
          <li>
            <ScrollLink to='projects' smooth duration={450} className='nav-links'>
              Projects
            </ScrollLink>
          </li>
        </ul>
        {/* CONTACT */}
        <ul className="footer-grid-contact-container">
          <p className='footer-contact-title'>Contact</p>
          <div className="contacts">
            {/* CONTACT LINKS */}
            <li>
              <a href='mailto:taileeena@gmail.com' className='contact-links'>
                taileeena@gmail.com 
              </a>
            </li>
            <li>
            <p style={{fontWeight: 'bold', marginBottom: '1rem'}}>Or via Outlook @PolyU</p>
              <a href='mailto:22076162d@connect.polyu.edu.hk' className='contact-links'>
                22076162d@connect.polyu.edu.hk
              </a>
            </li>
          </div>
        </ul>
        {/* SOCIAL */}
        <ul className="footer-grid-socials-container">
          <p className="footer-socials-title">Social Media</p>
          {/* SOCIAL LINKS */}
          <li>
            <a href="https://github.com/lenatai728"><span><FaGithub className='icon'/></span></a>
          </li>
          <li>
            <a href="https://x.com/LenaTai0728"><span><FaTwitter className='icon' /></span></a>
          </li>
          <li>
            <a href="https://www.linkedin.com/in/leena-tai-00361b323"><span><FaLinkedin className='icon'/></span></a>
          </li>
        </ul>
    </div>
      {/* COPYRIGHT */}
      <p className="copyright">
        &copy; {new Date().getFullYear()}{' '}
        <a href="/" className="copyright-link">
          LeeNA TAI
        </a>
        <span> All rights reserved. </span>
      </p>
    </footer>
  )
}

export default Footer
