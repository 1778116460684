import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from "./pages/Home";
import Projects from './components/Projects';

function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/projects" element={<Projects />}></Route>
          {/* <Route path="/work" element={<Work />}></Route> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
