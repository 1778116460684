import React, { useEffect, useRef } from 'react'
import { Link as ScrollLink } from 'react-scroll';
import { handleScroll } from '../utils/animation'

const Navbar = () => {
  // REF
  const navRef = useRef(null);
  // Animations: Navbar hide/show on Scroll
  useEffect(() => {
    let previousScrollPosition = window.scrollY;
    const handleScrollWithParams = () => {
      previousScrollPosition = handleScroll(previousScrollPosition, navRef);
    }
    window.addEventListener('scroll', handleScrollWithParams);

    return () => window.removeEventListener('scroll', handleScrollWithParams);
  }, []);
  
  return (
      <header ref={navRef}>
        <nav>
          <div className="logo">
            <h1>LeeNa TAI</h1>
          </div>
          <ul>
            <ScrollLink className="nav-links" to="about" smooth={true} duration={500}>Home</ScrollLink>
            <ScrollLink className="nav-links" to="skills" smooth={true} duration={500}>Skills</ScrollLink>
            <ScrollLink className="nav-links" to="projects" smooth={true} duration={500}>Projects</ScrollLink>
            {/* <ScrollLink className="nav-links" to="contacts" smooth={true} duration={500}>Contact Me</ScrollLink> */}
          </ul>
        </nav>
      </header>
  )
}

export default Navbar
