import React from 'react'
import ProjectCard from './ProjectCard'
import project1Img from '../images/project1.png'
import project2Img from '../images/project2.png'
import project3Img from '../images/project3.png'
import project4Img from '../images/project4.png'
import project5Img from '../images/project5.png'
import project6Img from '../images/project6.png'
import { project1, project2, project3, project4, project5, project6 } from '../utils/data'

const Projects = () => {

    return (
        <>
            <section className="projects">
                <h2>Projects</h2>
                <div className="projects-content">
                    <ProjectCard imgPath={project1Img} title={project1.title} tags={project1.tags} features={project1.features} showDemo={true} demoLink={project1.demoLink} showGithub={true} githubLink={project1.githubLink}/>
                    <ProjectCard imgPath={project6Img} title={project6.title} tags={project6.tags} features={project6.features} showDemo={project6.showDemo} githubLink={project6.githubLink} />
                    <ProjectCard imgPath={project2Img} title={project2.title} tags={project2.tags} features={project2.features} showDemo={true} demoLink={project2.demoLink} />
                    <ProjectCard imgPath={project3Img} title={project3.title} tags={project3.tags} features={project3.features} showDemo={project3.showDemo} githubLink={project3.githubLink} />
                    <ProjectCard imgPath={project4Img} title={project4.title} tags={project4.tags} features={project4.features} showDemo={project4.showDemo} githubLink={project4.githubLink} />
                    <ProjectCard imgPath={project5Img} title={project5.title} tags={project5.tags} features={project5.features} showDemo={project5.showDemo} githubLink={project5.githubLink} />
                </div>
            </section>
        </>
    )
}

export default Projects
