
export const project1 = {
    imgPath: '../images/project1.png',
    title: 'Tech Blog',
    tags: [
        {
            id: 3,
            content: 'React',
            color: 'var(--react)',
        },
        {
            id: 4,
            content: '#redux',
            color: 'var(--react)',
        },
        {
            id: 5,
            content: 'Firebase',
            color: 'var(--firebase)',
        },
        {
            id: 6,
            content: 'HTML',
            color: 'var(--html)',
        },
        {
            id: 7,
            content: 'CSS',
            color: 'var(--css)',
        },
        {
            id: 8,
            content: 'JavaScript',
            color: 'var(--javascript)',
        },
    ],
    features: [
        {
            id: 0,
            content: 'Real-time update web application using React.js for frontend & Firebase (free tier) for backend services'
        },
        {
            id: 1,
            content: 'Implemented features such as user account creation, sign-in/sign-up, blog post creation, and comment functionality'
        },
        {
            id: 2,
            content: 'Learned valuable skills in data modeling, authentication best practices, and real-time data management. Future plans include exploring alternative backend solutions and expanding the application features'
        },
    ],
    showDemo: true,
    githubLink: 'https://github.com/lenatai728/react-blog.git',
    demoLink: 'https://react-blog-4d7f0.web.app/'
}


export const project2 = {
    imgPath: '../images/project2.png',
    title: 'Currency Converter',
    tags: [
        {
            id: 0,
            content: 'HTML',
            color: 'var(--html)',
        },
        {
            id: 1,
            content: 'CSS',
            color: 'var(--css)',
        },
        {
            id: 2,
            content: 'JavaScript',
            color: 'var(--javascript)',
        },
    ],
    features: [
        {
            id: 0,
            content: 'Provides instant currency exchange rates and displays corresponding country flags, powered by the Flags API and ExchangeRate-API'
        },
        {
            id: 1,
            content: 'Offers a simple and intuitive design for easy currency selection and conversion.'
        },
    ],
    showDemo: true,
    githubLink: '',
    demoLink: 'https://lenatai-currency-converter.netlify.app/'
}
export const project3 = {
    imgPath: '../img/project3.png',
    title: 'Command-Line Task Management System',
    tags: [
        {
            id: 0,
            content: 'Java',
            color: 'var(--java)',
        },
    ],
    features: [
        {
            id: 0,
            content: 'Efficient Task Management System with MVC Architecture'
        },
        {
            id: 1,
            content: 'Implemented a robust MVC architecture for improved code organization and maintainability'
        },
        {
            id: 2,
            content: 'Provided a basic graphical user interface (GUI) using Java Swing'
        },
        {
            id: 3,
            content: 'Enhanced user experience with undo and redo capabilities for command-line interface operations'
        },
        {
            id: 4,
            content: 'Enabled users to store and load tasks and criteria from/to files for persistent data management'
        },
    ],
    showDemo: false,
    githubLink: 'https://github.com/lenatai728/Command-Line-Task-Management-System.git'
}
export const project4 = {
    imgPath: '../images/project4.png',
    title: 'Online Shopping System',
    tags: [
        {
            id: 0,
            content: 'Oracle',
            color: 'var(--oracle)',
        },
        {
            id: 1,
            content: 'Java',
            color: 'var(--java)',
        },
    ],
    features: [
        {
            id: 0,
            content: 'Using an Oracle database provided by Polyu COMP Department to manage user accounts, product listings, and transactions'
        },
        {
            id: 1,
            content: 'Implemented features such as User Account Management, Product Listings and Search, Shopping Cart and Checkout, Inventory Management & Reporting and Analytics'
        },
    ],
    showDemo: false,
    githubLink: 'https://github.com/lenatai728/Online-Shopping-System.git'
}
export const project5 = {
    imgPath: '../images/project5.png',
    title: 'Steel-Making Production Line Scheduler',
    tags: [
        {
            id: 0,
            content: 'C',
            color: 'var(--firebase)',
        },
    ],
    features: [
        {
            id: 0,
            content: 'Supports multi-plant scheduling with varying production capacities'
        },
        {
            id: 1,
            content: 'Implemented 4 scheduling algorithms (FCFS, PR, SJF, LLF) to optimize production flow'
        },
        {
            id: 2,
            content: 'Provides a user-friendly interface for inputting commands, adding orders, and creating activities'
        },
        {
            id: 3,
            content: 'Allows loading orders from batch files and exporting scheduling reports to text files'
        },
    ],
    showDemo: false,
    githubLink: 'https://github.com/lenatai728/Steel-Making-Production-Line-Scheduler.git'
}

export const project6 = {
    imgPath: '../images/project6.png',
    title: 'eBigMart Express API',
    tags: [
        {
            id: 0,
            content: 'Node.js',
            color: 'var(--javascript)',
        },
        {
            id: 0,
            content: 'Express',
            color: 'var(--css)',
        },
        {
            id: 0,
            content: 'MongoDB',
            color: 'var(--mongodb)',
        },
    ],
    features: [
        {
            id: 0,
            content: 'Developed user authentication, product, user, and order RESTful APIs with CRUD operations'
        },
        {
            id: 1,
            content: 'Employed Cookie Parser for the handling of user authentication via web cookies'
        },
        {
            id: 2,
            content: 'Utilized JWT (JSON Web Token) for generation and verification of web tokens'
        },
        {
            id: 3,
            content: 'Implemented secure password hashing using Bcrypt.js for user security measures'
        },
        {
            id: 4,
            content: 'Conducted thorough testing of API endpoints using Postman to ensure functionality and reliability'
        },
    ],
    showDemo: false,
    githubLink: 'https://github.com/lenatai728/eBigMart-Express-API.git'
}


