import { useRef  } from "react";
import About from "../components/About";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Projects from "../components/Projects";
import Skills from "../components/Skills";

function Home() {
  const sectionRefs = useRef([]);
  const registerSectionRef = (ref) => {
    sectionRefs.current.push(ref);
  };
  return (
    <div className="app">
      {/* Navbar */}
      <Navbar /> 
      {/* Container */}
      <div className="main-container">
        <main>
          <section className="about" ref={registerSectionRef}>
            <About />
          </section>
          <section id="skills" ref={registerSectionRef}>
            <Skills />
          </section>
          <section id="projects" ref={registerSectionRef}>
            <Projects />
          </section>
          {/* <section id="work" ref={registerSectionRef}>
            <Work />
          </section> */}
        </main>
      </div>
      {/* Footer */}
      <Footer />
    </div>
  );
}

export default Home;
