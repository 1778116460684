import React from 'react'

const ProjectCard = ({ imgPath, title, tags, features, showDemo, showGithub,githubLink, demoLink }) => {


    return (
        <div className="project-card" onClick={()=> { window.location.href = showDemo? demoLink : githubLink }}>
            <div className="project-img">
                <img src={imgPath} alt='project'></img>
            </div>
            <div className="project-content">
                {/* TAGS */}
                <div className="project-tags">
                    {
                        tags.map(tag => (
                            <span key={tag.id} className="tag" style={{ color: tag.color,  border: `1px solid ${tag.color}` }}>{tag.content}</span>
                        ))
                    }
                </div>
                <h3>{title}</h3>
                {/* FEATURES */}
                <ul className="project-feature">
                    {
                        features.map(feature => (
                            <li key={feature.id}>{feature.content}</li>
                        ))
                    }
                </ul>
                {/* BUTTONS */}
                {
                    showDemo && (
                        <a className="btn" href={demoLink} >View Demo</a>
                    )
                }
                {
                    (!showDemo || showGithub) && (
                        <a className="btn" href={githubLink}>View on Github</a>
                    )
                }
            </div>
        </div>
    )
}

export default ProjectCard
