
// Navbar OnScroll hide/show Animation 
// use with: useEffect, useRef in <header>, header: position fixed

export const handleScroll = (previousScrollPosition, navRef) => {

    const currentScrollPosition = window.scrollY;
    const navElement = navRef.current;

    if (!navElement) return;
    if (previousScrollPosition > currentScrollPosition) {
      navElement.style.transform = 'translateY(0)';
      navElement.style.transition = '350ms';
    } else {
      navElement.style.transform = 'translateY(-110px)';
      navElement.style.transition = '800ms';
    }
    previousScrollPosition = currentScrollPosition;
    
    return currentScrollPosition;
};