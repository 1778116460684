import React from 'react'
import './skills.styled.css';

const Skills = () => {
  return (
    <>
      <section className="skills">
        <h1>Skills</h1>
        <p>Currently</p>
        <div className="skills-container">
            <div className="skill">
                <i className='bx bxl-react' ></i>
                <h1>Frontend</h1>    
                <h2>Meta Professional Frontend Developer Certificates</h2>
                <a href="https://github.com/lenatai728/CERTIFICATES/blob/main/Meta%20Professional%20Frontend%20Developer%20Certficates/Meta%20Professional%20Frontend%20Developer%20Certificate.pdf">See Credential</a>
                <ul className="skill-details">
                    <li>Proficient in React.js</li>
                    <li>Strong foundation in HTML & CSS</li>
                    <li>Experienced with JavaScript ES6+ & Git & CSS frameworks including Bootstrap and TailwindCSS </li>
                </ul>
                <div className="line"></div>
            </div>
            <div className="skill">
                <i className='bx bx-edit-alt'></i>
                <h1>UI</h1>
                <h2 style={{marginBottom: '3.3rem'}}>Responsive Web Design</h2>
                <a href="https://github.com/lenatai728/CERTIFICATES/blob/main/FreeCodeCamp/freecodecamp%20Response%20Design%20Certfificate.png">See Credential</a>
                <ul className="skill-details">
                    <li>Familiar with CSS including Responsive Design techniques</li>
                    <li>Understanding UX/UI Design Principles</li>
                    <li>Experienced with Figma</li>
                </ul>
                <div className="line"></div>
            </div>
            <div className="skill">
                <i className='bx bx-data'></i>
                <h1>RESTful APIs & Backend</h1>
                <h2>Node.js, Express.js, MongoDB, Oracle, Python, Java, C/C++</h2>
                <a href="https://api.badgr.io/public/assertions/7KxnmB9oSCuT-6pIATsp5w?identity__email=taileeena%40gmail.com">See Credential</a>
                <ul className="skill-details">
                    <li>Skilled in fetching and posting data from APIs using HTTP methods</li>
                    <li>In ability to design, develop, and implement RESTful APIs using Node.js and Express.js, handling HTTP requests, responses, and data routing</li>
                    <li>Experienced in BaaS (Firebase & MongoDB)</li>
                </ul>
                <div className="line"></div>
            </div>
        </div>
      </section>
    </>
  )
}

export default Skills
